import { Card, CardContent, Skeleton } from "@mui/material"
import { FC } from "react"

export const TranscriptListItemSkeleton: FC<{ isSelected?: boolean }> = ({ isSelected = false }) => {
  return (
    <Card
      sx={{
        border: isSelected ? 3 : 1,
        borderColor: "primary.dark",
        marginBottom: 1,
        position: "relative",
      }}
      variant="outlined"
    >
      <CardContent sx={{ paddingY: 0, "&:last-child": { paddingBottom: 0.5 } }}>
        {/* Simulate action bar */}
        <Skeleton variant="rounded" width="10%" height={30} sx={{ mt: 1 }} />
        {/* Simulate a title */}
        <Skeleton variant="text" width="60%" height={30} />
        {/* Simulate a subtitle or detail line */}
        <Skeleton variant="text" width="80%" height={20} sx={{ mt: 1 }} />
        {/* Another detail line */}
        <Skeleton variant="text" width="40%" height={20} sx={{ mt: 1 }} />
      </CardContent>
    </Card>
  )
}
