import { FetchedMeeting } from "@api/meetings"
import { MeetingSearchResult } from "@api/search"
import { TranscriptList } from "@components/MeetingDetail/TranscriptsTab/TranscriptList"
import { TranscriptListItemSkeleton } from "@components/MeetingDetail/TranscriptsTab/TranscriptList/TranscriptListItemSkeleton"
import { NoResultsContainer } from "@components/shared/NoResultsContainer"
import { TranscriptWithPerson } from "@interfaces/transcript"
import { Grid, List, useMediaQuery, useTheme } from "@mui/material"
import { MeetingPageContext } from "@pages/MeetingPage"
import { FC, useContext, memo } from "react"

export interface TranscriptListContainerProps {
  searchResults: MeetingSearchResult
  transcripts: [string, TranscriptWithPerson][]
  meetingData: FetchedMeeting
  isLoading: boolean
}

const UnmemoedTranscriptsListContainer: FC<TranscriptListContainerProps> = ({
  searchResults,
  transcripts,
  meetingData,
  isLoading,
}: TranscriptListContainerProps) => {
  const { searchTermsInputRef } = useContext(MeetingPageContext)
  const theme = useTheme()
  const smallScreen = useMediaQuery(theme.breakpoints.down("md"))
  return searchResults.total_hits == 0 ? (
    <div onClick={() => searchTermsInputRef.current.focus()}>
      <NoResultsContainer
        minHeight="20vh"
        subHeading="Change your search terms and try again!"
        subHeaderSx={{ textDecoration: "underline", cursor: "pointer" }}
      />
    </div>
  ) : (
    <Grid container>
      <List
        sx={{
          overflow: "auto",
          maxHeight: smallScreen ? 320 : 580,
          marginBottom: 1,
          width: "100%",
        }}
      >
        {isLoading ? (
          [1, 2, 3, 4, 5].map((i) => <TranscriptListItemSkeleton key={i} />)
        ) : (
          <TranscriptList transcripts={transcripts} meetingData={meetingData} />
        )}
      </List>
    </Grid>
  )
}

export const TranscriptsListContainer = memo(UnmemoedTranscriptsListContainer)
