import {
  FC,
  useState,
  useEffect,
  useContext,
  useRef,
  MutableRefObject,
  createContext,
  Dispatch,
  SetStateAction,
} from "react"
import { Box, Stack, useMediaQuery, useTheme } from "@mui/material"
import { Helmet } from "react-helmet"
import { Context } from "@src/components/MeetingDetail/Shared/Context"
import { VideoCard } from "@src/components/MeetingDetail/VideoCard"
import Tabs from "@src/components/shared/Tabs/HashTabs"
import { TranscriptsTabContent } from "@src/components/MeetingDetail/TranscriptsTab"
import { StatisticsDetailsCard } from "@src/components/MeetingDetail/StatisticsDetailsCard"
import { useIsSuperAdmin, useIsInsightsEnabled, useIsOpportunitiesEnabled } from "@api/users"
import { SendInsightCard } from "@src/components/MeetingDetail/SendInsightCard"
import { TimelineAccordion } from "@src/components/MeetingDetail/TimelineAccordion"
import { MobileVideo } from "@src/components/MeetingDetail/VideoCard/MobileVideo"
import { useLocation, useParams } from "react-router-dom"
import { IMeetingRouteParams } from "@api/interfaces"
import { FetchedMeeting, useFetchMeeting } from "@api/meetings"
import { GeneralInsightCard } from "@src/components/MeetingDetail/InsightTab"
import { useMarkViewed } from "@api/meetings"
import { SpeakerIdentificationComponent } from "@components/Admin/Meetings/SpeakerIdentificationTable"
import { useFetchCurrentUser } from "@api/users"
import { PersonalInsight } from "@src/components/MeetingDetail/PersonalInsightTab"
import { Opportunities } from "@src/components/MeetingDetail/OpportunitiesTab"
import Loading from "@src/components/shared/Loading"
import { getMeetingVoices } from "@api/meetings"
import { VoiceWithSpeaker } from "@interfaces/voice"
import { Contact } from "@interfaces/contact"

export interface MeetingPageContextProps {
  meeting: FetchedMeeting
  setMeeting: (meeting: FetchedMeeting) => void
  voices: VoiceWithSpeaker<Contact>[]
  setVoices: Dispatch<SetStateAction<VoiceWithSpeaker<Contact>[]>>
  isLoading: boolean
  voicesIsLoading: boolean
  searchTermsInputRef: MutableRefObject<HTMLInputElement>
}

export const MeetingPageContext = createContext<MeetingPageContextProps>({
  meeting: {} as FetchedMeeting,
  setMeeting: () => {
    /* placeholder for typescript */
  },
  isLoading: false,
  voices: [],
  setVoices: () => {
    /* placeholder for typescript */
  },
  voicesIsLoading: false,
  searchTermsInputRef: {} as MutableRefObject<HTMLInputElement>,
})

export const MeetingPage: FC = () => {
  const { data: isSuperAdmin } = useIsSuperAdmin()
  const { setMeeting, setVoices } = useContext(MeetingPageContext)
  const { meetingId } = useParams<IMeetingRouteParams>()
  const { data: meetingData, isLoading } = useFetchMeeting(meetingId, (data) => {
    setMeeting(data)
  })
  const { data: insightsEnabled } = useIsInsightsEnabled()
  const { data: opportunitiesEnabled } = useIsOpportunitiesEnabled()

  const { data: currentUser } = useFetchCurrentUser()

  const { data: voiceData, isLoading: voiceDataLoading, isRefetching } = getMeetingVoices(meetingId)

  const theme = useTheme()
  const isMd = useMediaQuery(theme.breakpoints.up("md"))

  const searchString = useLocation().search
  const params = new URLSearchParams(searchString)
  const startTimeSeconds = params.get("startTimeSeconds")
  const { mutate } = useMarkViewed(meetingId)

  const [progress, setProgress] = useState<[number, number]>([0, 0])
  const [state, setState] = useState({
    videoStartTime: startTimeSeconds
      ? { startTime: parseInt(startTimeSeconds), change: false }
      : { startTime: 0, change: false },
    playingVideo: false,
    isReady: false,
  })
  const searchTermsInputRef = useRef<HTMLInputElement>({} as HTMLInputElement)

  useEffect(() => {
    mutate()
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    if (voiceData) {
      setVoices(voiceData)
    }
  }, [voiceData])

  if (isLoading || !meetingData) {
    return <Loading />
  }

  const tabs = [
    {
      hash: "",
      label: "Transcripts",
      component: <TranscriptsTabContent />,
    },
    {
      hash: "briefing",
      label: "Insight",
      component: <GeneralInsightCard />,
    },
  ]

  if (insightsEnabled) {
    tabs.push({
      hash: "personal-briefing",
      label: "Personal Insight",
      component: <PersonalInsight />,
    })
  }

  if (opportunitiesEnabled) {
    tabs.push({
      hash: "opportunities",
      label: "Opportunities",
      component: <Opportunities id={meetingId} />,
    })
  }

  return (
    <Context.Provider value={{ state, setState, progress, setProgress }}>
      <MeetingPageContext.Provider
        value={{
          meeting: meetingData.meeting || ({} as FetchedMeeting),
          setMeeting,
          voices: voiceData || [],
          setVoices,
          isLoading,
          voicesIsLoading: voiceDataLoading || isRefetching,
          searchTermsInputRef,
        }}
      >
        <Helmet>
          <title>{`Cloverleaf.AI | ${meetingData.meeting.title || "Meeting"}`}</title>
        </Helmet>
        <Box
          marginBottom={{
            xs: `${window.innerWidth * 0.5625 + 16}px`,
            md: "0",
          }}
        >
          <Stack direction="row">
            <Stack direction="column" padding={2} spacing={2} width={{ xs: "100%", md: "50%" }}>
              <VideoCard />
              {!isMd && <Tabs gap={1} tabs={tabs} />}
              <StatisticsDetailsCard />
              {currentUser?.permissions?.includes("speaker_identification") && <SpeakerIdentificationComponent />}
              {isSuperAdmin && <SendInsightCard />}
              {process.env.REACT_APP_GOOGLE_ENV !== "production" && <TimelineAccordion />}
            </Stack>
            {isMd && <Tabs gap={1} tabs={tabs} width="50%" padding={2} />}
          </Stack>
        </Box>
        <MobileVideo />
      </MeetingPageContext.Provider>
    </Context.Provider>
  )
}
