import { FC } from "react"
import { IconButton, ListItem, ListItemButton, ListItemText, Typography } from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"
import { useHistory, useLocation } from "react-router"
import { useCreateSuggestedTerm } from "@api/suggested_terms"
import { addSearchTermString } from "@functions/generateURL"

export const KeyTermContent: FC<{
  meeting: any
  word: string
  hideClose?: boolean
}> = ({ meeting, word, hideClose }) => {
  const locationSearchParams = useLocation()
  const history = useHistory()
  const { mutate: createSuggestedTerm } = useCreateSuggestedTerm()

  const onWordSelect = (searchTerm: string) => {
    createSuggestedTerm({ meeting_id: meeting?.id, user_sentiment: "positive", term: searchTerm })

    history.push({ search: addSearchTermString(locationSearchParams.search, searchTerm) })
  }

  return (
    <ListItem
      disablePadding
      secondaryAction={
        hideClose ? (
          <></>
        ) : (
          <IconButton
            edge="end"
            aria-label="delete"
            onClick={() => createSuggestedTerm({ meeting_id: meeting?.id, user_sentiment: "negative", term: word })}
          >
            <CloseIcon />
          </IconButton>
        )
      }
    >
      <ListItemButton onClick={() => onWordSelect(word)} key={word}>
        <ListItemText primary={<Typography variant="body1">{word}</Typography>} />
      </ListItemButton>
    </ListItem>
  )
}
