import { mapStringsToIntegers } from "@functions/dataTypeConversion"

export const removeSearchTermString = (prevParams: string | URLSearchParams, removeTerm: string) => {
  const params = new URLSearchParams(prevParams)
  const terms = params.getAll("terms")

  params.delete("terms")
  terms.filter((term) => term !== removeTerm).forEach((term) => params.append("terms", term))
  return params.toString()
}

export const addSearchTermString = (prevParams: string | URLSearchParams, newTerm: string) => {
  const params = new URLSearchParams(prevParams)
  const terms = params.getAll("terms")
  terms.push(newTerm)
  const uniqueTerms = new Set<string>(terms)

  params.delete("terms")
  uniqueTerms.forEach((term) => params.append("terms", term))
  return params.toString()
}

export const setVoiceIdsString = (prevParams: string | URLSearchParams, voiceIds: number[]) => {
  const params = new URLSearchParams(prevParams)
  params.delete("voice-ids")
  const uniqueVoiceIds = new Set<number>(voiceIds)
  uniqueVoiceIds.forEach((voiceId) => params.append("voice-ids", voiceId.toString()))
  return params.toString()
}

export const parseStringListParam = (paramValue: string | null | undefined, separator: string = ","): string[] => {
  return (paramValue || "").split(separator)
}

export const parseUniqueStringListParam = (
  paramValue: string | null | undefined,
  separator: string = ",",
): Set<string> => {
  return new Set(parseStringListParam(paramValue, separator))
}

export const parseIntegerListParam = (paramValue: string | null | undefined, separator: string = ","): number[] => {
  return mapStringsToIntegers(parseStringListParam(paramValue, separator))
}

export const parseUniqueIntegerListParam = (
  paramValue: string | null | undefined,
  separator: string = ",",
): Set<number> => {
  return new Set(parseIntegerListParam(paramValue, separator))
}

export const stringifyListParam = (
  paramValueCollection: Set<string | number> | number[] | string[],
  separator: string = ",",
): string => {
  return Array.from(paramValueCollection)
    .map((el: string | number) => `${el}`)
    .join(separator)
}

export interface MeetingDetailsParamsStringProps {
  terms?: string[] | null
  transcriptId?: string | number | null
  activeTerm?: string | null
  startTime?: number | null
  prevParams?: string | URLSearchParams
  proximity?: number | null
  mustHaveTerms?: string[] | null
  exclude?: boolean | null
  refresh?: boolean | null
  voiceIds?: number[] | null
  titleSearchTerms?: string[] | null //Person title search
  organizationSearchTerms?: string[] | null //Person organization search
}

export const generateParamsString = (paramsStringProps?: MeetingDetailsParamsStringProps) => {
  if (!paramsStringProps) {
    return ""
  }
  const {
    terms,
    transcriptId,
    activeTerm,
    startTime,
    prevParams,
    proximity,
    mustHaveTerms,
    exclude,
    refresh,
    voiceIds,
    titleSearchTerms,
    organizationSearchTerms,
  } = paramsStringProps
  const params = new URLSearchParams(prevParams)
  if (terms === null) {
    params.delete("terms")
  } else if (terms) {
    params.delete("terms")
    terms.forEach((term) => params.append("terms", term))
  }
  if (transcriptId === null) {
    params.delete("transcript_id")
  } else if (transcriptId) {
    params.set("transcript_id", transcriptId.toString())
  }
  if (activeTerm === null) {
    params.delete("active-term")
  } else if (activeTerm) {
    params.set("active-term", activeTerm)
  }
  if (startTime === null) {
    params.delete("startTimeSeconds")
  } else if (startTime) {
    params.set("startTimeSeconds", startTime.toString())
  }
  if (exclude === null) {
    params.delete("exclude")
  } else if (exclude !== undefined) {
    params.set("exclude", exclude.toString())
  }
  if (proximity === null) {
    params.delete("proximity")
  } else if (proximity) {
    params.set("proximity", proximity.toString())
  }
  if (mustHaveTerms === null) {
    params.delete("must-have-terms")
  } else if (mustHaveTerms) {
    params.delete("must-have-terms")
    mustHaveTerms.forEach((term) => params.append("must-have-terms", term))
  }
  if (refresh === null) {
    params.delete("refresh")
  } else if (refresh) {
    params.set("refresh", refresh.toString())
  }
  if (voiceIds === null) {
    params.delete("voice-ids")
  }
  if (voiceIds) {
    params.delete("voice-ids")
    voiceIds.forEach((voiceId) => params.append("voice-ids", voiceId.toString()))
  }
  if (titleSearchTerms === null) {
    params.delete("title-search-terms")
  }
  if (titleSearchTerms) {
    titleSearchTerms.forEach((term) => params.append("title-search-terms", term))
  }
  if (organizationSearchTerms === null) {
    params.delete("organization-search-terms")
  }
  if (organizationSearchTerms) {
    organizationSearchTerms.forEach((term) => params.append("organization-search-terms", term))
  }
  return params.toString()
}

export const generateURL = (meetingId: string | number, paramsStringProps?: MeetingDetailsParamsStringProps) => {
  const paramsString = generateParamsString(paramsStringProps)
  return `/meetings/${meetingId}${paramsString ? "?" : ""}${paramsString}`
}

export const generateMinnieURL = (prefix: string) => {
  if (!process.env.REACT_APP_GOOGLE_ENV) {
    const searchHostUrl = process.env.REACT_APP_SEARCH_API_URL || "http://localhost:8001/"
    prefix = `${searchHostUrl}${prefix}`
  }
  return prefix
}
