import React, { FC, useCallback, useContext, useEffect, useMemo, useState } from "react"
import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  BoxProps,
  Autocomplete,
  Typography,
} from "@mui/material"
import SearchIcon from "@mui/icons-material/Search"
import { useHistory, useLocation, useParams } from "react-router-dom"
import { addSearchTermString, removeSearchTermString, setVoiceIdsString } from "@functions/generateURL"
import { useMeetingSearch } from "@api/search"
import { IMeetingRouteParams } from "@api/interfaces"
import { SearchTermChips } from "../SearchTermChips"
import { MeetingPageContext } from "@pages/MeetingPage"
import { Person } from "@interfaces/person"
import { Contact } from "@interfaces/contact"
import { decidePersonFields } from "@functions/person"
import { Voice } from "@interfaces/voice"
import { useFetchCurrentUser } from "@api/users"
import { vitallyTrack } from "@config/vitally"
import { useDebouncedCallback } from "use-debounce"

type PersonWithVoice = Omit<Person<Contact>, "voice"> & { voice: Voice<Contact> }

export const TranscriptListSearch: FC<BoxProps> = (props) => {
  const { data: currentUser } = useFetchCurrentUser()
  const history = useHistory()
  const locationSearchParams = useLocation().search
  const params = new URLSearchParams(locationSearchParams)

  const terms = params.getAll("terms")
  const { meetingId } = useParams<IMeetingRouteParams>()
  const { data, isFetching } = useMeetingSearch(params, meetingId)
  const termHits: { [index: string]: number } = Object.fromEntries(terms.map((term) => [term, 0]))

  data?.terms.forEach((term_hit) => {
    const transcriptHits = term_hit.transcripts
    if (transcriptHits.length) {
      termHits[term_hit.term] = term_hit.hits
    }
  })

  const addTerm = (searchInput: string) => {
    vitallyTrack(currentUser, "Meeting Add Search Term", { meetingId: meetingId, searchTerm: searchInput.trim() })
    history.push({ search: addSearchTermString(locationSearchParams, searchInput) })
  }

  const removeTerm = (searchTerm: string) => {
    history.push({ search: removeSearchTermString(locationSearchParams, searchTerm) })
  }

  const { voices, voicesIsLoading } = useContext(MeetingPageContext)
  const [peopleOptions, setPeopleOptions] = useState<PersonWithVoice[]>([])

  useEffect(() => {
    if (voicesIsLoading) {
      return
    }
    const people: PersonWithVoice[] = voices
      .filter((voice) => voice.person && voice.verification_status !== "unverified")
      .map((voice) => ({
        ...voice.person!,
        voice: {
          id: voice.id,
          verification_status: voice.verification_status,
          person_id: voice.person_id,
          updated_at: voice.updated_at,
          created_at: voice.created_at,
          discarded_at: voice.discarded_at,
        },
      }))
    setPeopleOptions(people)
  }, [JSON.stringify(voices), voicesIsLoading])

  const callVitallyTrackForSearch = (terms: string[]) => {
    const vitallyParams = {
      meetingId: meetingId,
      searchTerms: terms,
      anchorTerms: params.getAll("must-have-terms"),
      proximitySeconds: params.get("proximity"),
      exclude: params.get("exclude"),
      voiceIds: params.getAll("voice-ids"),
      titleSearchTerms: params.getAll("title-search-terms"),
      organizationSearchTerms: params.getAll("organization-search-terms"),
    }
    vitallyTrack(currentUser, "Meeting Search", vitallyParams)
  }

  useEffect(() => {
    if (isFetching && currentUser) {
      callVitallyTrackForSearch(terms)
    }
  }, [isFetching, currentUser])

  return (
    <Box {...props}>
      <TranscriptSearch addTerm={addTerm} peopleOptions={peopleOptions} />
      <Stack direction="row" flexWrap="wrap" gap={0.5} marginTop={0.5}>
        <SearchTermChips termHits={termHits} onDelete={removeTerm} />
      </Stack>
    </Box>
  )
}

interface TranscriptPersonSearchProps {
  options: PersonWithVoice[]
}

const TranscriptPersonSearch: FC<TranscriptPersonSearchProps> = ({ options }) => {
  const { voicesIsLoading } = useContext(MeetingPageContext)
  const locationSearchParams = useLocation().search
  const voiceIds = new URLSearchParams(locationSearchParams).getAll("voice-ids")
  const history = useHistory()

  const [peopleChosen, setPeopleChosen] = useState<PersonWithVoice[]>(() =>
    options.filter((person) => voiceIds.includes(person.voice.id.toString())),
  )

  const debouncedUpdateSearch = useDebouncedCallback((people: PersonWithVoice[]) => {
    const newSearch = setVoiceIdsString(
      locationSearchParams,
      people.map((person) => person.voice.id),
    )
    history.push({ search: newSearch })
  }, 300)

  useEffect(() => {
    debouncedUpdateSearch(peopleChosen)
  }, [peopleChosen, debouncedUpdateSearch])

  const getOptionLabel = useCallback((option) => {
    const { name, title, organization } = decidePersonFields(option, false)
    return `${name}, ${title}${organization ? `, ${organization}` : ""}`
  }, [])

  return (
    <Autocomplete
      options={options}
      multiple
      disableCloseOnSelect
      getOptionLabel={getOptionLabel}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      value={peopleChosen}
      onChange={(event, newValue) => {
        setPeopleChosen(newValue)
      }}
      sx={{
        width: "40%",
      }}
      size="small"
      renderInput={(params) => (
        <TextField
          {...params}
          label={params.inputProps.value ? "" : "Person"}
          InputProps={{
            ...params.InputProps,
          }}
        />
      )}
      renderTags={(values) => (
        <Box sx={{ display: "flex", whiteSpace: "nowrap", paddingX: 1, maxWidth: "100%" }}>
          <Typography
            sx={{
              marginRight: 0.5,
            }}
            noWrap
          >
            {values
              .map((value) => decidePersonFields(value, false).name)
              .join(", ")
              .replace(/,\s*$/, "")}
          </Typography>
        </Box>
      )}
      loading={voicesIsLoading}
      placeholder="Search by name"
    />
  )
}

interface TranscriptListSearchProps {
  addTerm: (searchInput: string) => void
  peopleOptions: PersonWithVoice[]
}

const TranscriptSearch: FC<TranscriptListSearchProps> = ({ addTerm, peopleOptions }) => {
  const { searchTermsInputRef } = useContext(MeetingPageContext)
  const [searchInput, setSearchInput] = useState("")

  const handleAddTerm = (searchInput: string) => {
    addTerm(searchInput)
    setSearchInput("")
  }

  return (
    <Stack direction="row" spacing={1}>
      <TextField
        sx={{ flexGrow: 1 }}
        value={searchInput}
        onChange={(event) => setSearchInput(event.target.value)}
        size="small"
        placeholder="Enter a search term/phrase"
        onKeyDown={(event) => {
          if (event.key === "Enter") {
            handleAddTerm(searchInput)
          }
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end" sx={{ display: { md: "none" } }}>
              <IconButton onClick={() => handleAddTerm(searchInput)} edge="end">
                <SearchIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
        inputRef={searchTermsInputRef}
      />
      <TranscriptPersonSearch options={peopleOptions} />
      <Button
        variant="contained"
        onClick={() => handleAddTerm(searchInput)}
        sx={{ display: { xs: "none", md: "block" }, flexShrink: 0 }}
      >
        Search
      </Button>
    </Stack>
  )
}
