import { FC } from "react"
import { TranscriptListItem } from "./TranscriptListItem"
import { TranscriptWithPerson } from "@interfaces/transcript"
import { FetchedMeeting } from "@src/api/meetings"

export const TranscriptList: FC<{
  transcripts: [string, TranscriptWithPerson][]
  meetingData: FetchedMeeting
}> = ({ transcripts, meetingData }) => {
  return (
    <>
      {transcripts.map(([term, transcript]) => (
        <div key={`tli-${transcript.id}-${term}`}>
          <TranscriptListItem transcript={transcript} searchTerm={term} meetingData={meetingData} />
        </div>
      ))}
    </>
  )
}
