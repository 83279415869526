import { FC, useContext } from "react"
import { useLocation } from "react-router-dom"
import { HomePageContext } from "@pages/HomePage"
import { useSearch } from "@api/search"
import { MeetingListItem } from "../MeetingListItem"
import { Meeting } from "@interfaces/meeting"
import { decodeBase64Params } from "@functions/common"

export const ListView: FC = () => {
  const locationSearchParams = useLocation().search
  const queryParams = new URLSearchParams(locationSearchParams)
  const homePageContext = useContext(HomePageContext)
  const { searchTerms, mustIncludeTerms, proximity, exclude, base64FilterParams } = homePageContext.homePageParams
  const { data: meetingsSearchData } = useSearch(queryParams)

  const meetingsMap = new Map<number, Meeting>()

  const { voice_ids, title_search_terms, organization_search_terms } = decodeBase64Params<{
    voice_ids?: number[]
    title_search_terms?: string[]
    organization_search_terms?: string[]
  }>(base64FilterParams, { voice_ids: undefined, title_search_terms: undefined, organization_search_terms: undefined })

  meetingsSearchData?.meetings.forEach((meeting) => meetingsMap.set(meeting.id, meeting))
  const searchResult = meetingsSearchData?.meeting_hits.map((meetingHit) => {
    const meeting = meetingsMap.get(meetingHit.id)
    if (meeting) {
      return (
        <MeetingListItem
          meeting={meeting}
          hits={meetingHit}
          key={meeting.id}
          searchWords={searchTerms}
          hasViewed={meetingHit.already_viewed}
          proximity={proximity}
          mustHaveTerms={mustIncludeTerms}
          exclude={exclude}
          voiceIds={voice_ids}
          titleSearchTerms={title_search_terms}
          organizationSearchTerms={organization_search_terms}
        />
      )
    } else {
      return null
    }
  })
  return <>{searchResult}</>
}
