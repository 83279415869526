import { FC, useContext, useEffect, ReactNode, useState, useMemo } from "react"
import { Divider, Stack, Typography, Box } from "@mui/material"
import { TranscriptListItem } from "./TranscriptList/TranscriptListItem"
import { Transcript, TranscriptWithPerson } from "@interfaces/transcript"
import { ClipShareContext } from "../../Promo/share/ClipShare"
import { useLocation, useParams, useHistory } from "react-router-dom"
import { IMeetingRouteParams } from "@api/interfaces"
import { generateParamsString } from "@functions/generateURL"
import { getWordTime } from "@functions/transcript-words-array"
import { BookmarkContext, Context } from "../Shared/Context"
import { unpackSetters } from "../../shared/unpackSetters"
import { useMeetingSearch, MeetingSearchResult } from "@api/search"
import { useFetchMeetingTranscripts } from "@api/meetings"
import { useFetchMyClipSharesInBookmarks } from "@api/clip_shares"
import BookmarkEditor from "../Bookmark/BookmarkEditor"
import { ShareModal } from "../../shared/ShareModal"
import { TotalMatchesChip } from "./TotalMatchesChip"
import { TranscriptsListContainer } from "@src/containers/MeetingDetailContainers/TranscriptsListContainer"
import { MeetingPageContext } from "@pages/MeetingPage"
import { TranscriptListItemSkeleton } from "./TranscriptList/TranscriptListItemSkeleton"
import { matchPersonToTranscriptFromVoices } from "@functions/person"

export const TranscriptsTabContent: FC = () => {
  const { meeting, voices } = useContext(MeetingPageContext)
  const { meetingId } = useParams<IMeetingRouteParams>()
  const searchString = useLocation().search
  const params = new URLSearchParams(searchString)

  const { state, setState } = useContext(Context)
  const { setVideoStartTime } = unpackSetters(state, setState)

  const terms = params.getAll("terms")
  const voiceIds = params.getAll("voice-ids")
  const {
    data,
    isLoading: isMeetingSearchLoading,
    isRefetching: isMeetingSearchRefetching,
  } = useMeetingSearch(params, meetingId)
  const refresh = params.get("refresh")
  const { data: transcriptsData, isLoading: transcriptDataIsLoading } = useFetchMeetingTranscripts(meetingId)
  const history = useHistory()

  const { data: fetchMyClipsData, refetch } = useFetchMyClipSharesInBookmarks()
  const [selectedTranscriptId, setSelectedTranscriptId] = useState<string>()
  const [selectedComponent, setSelectedComponent] = useState<ReactNode>()

  const findSelectedTranscript = (
    transcripts: Transcript[],
    transcriptId: string,
    id_column: "id" | "extra_transcript_id",
  ): Transcript | undefined => {
    return transcripts.find((transcript) => {
      if (typeof transcript[id_column] === "number") {
        return transcript[id_column] === parseInt(transcriptId, 10)
      }
      return transcript[id_column] === transcriptId
    })
  }

  const obtainSelectedTranscript = (transcripts: Transcript[], transcriptId: string) => {
    const selectedTranscript = findSelectedTranscript(transcripts, transcriptId, "id")
    if (selectedTranscript) {
      return selectedTranscript
    }
    return findSelectedTranscript(transcripts, transcriptId, "extra_transcript_id")
  }

  useEffect(() => {
    // Refetch the data when the component mounts
    refetch()
  }, [])

  useEffect(() => {
    // Check if param transcript_id is null and return if it is
    const transcriptId = params.get("transcript_id")
    if (!transcriptId) {
      return
    }
    setSelectedTranscriptId(transcriptId)
  }, [params.get("transcript_id")])

  const [transcriptIdsToBookmarkIds, setTranscriptIdsToBookmarkIds] = useState<Record<string, number[]>>({})

  useEffect(() => {
    if (!fetchMyClipsData) {
      return
    }
    setTranscriptIdsToBookmarkIds(
      Object.values(fetchMyClipsData).reduce((acc, transcriptToBookmarkIds) => {
        Object.entries(transcriptToBookmarkIds).forEach(([transcriptId, bookmarkIds]) => {
          acc[transcriptId] = bookmarkIds
        })
        return acc
      }, {}),
    )
  }, [fetchMyClipsData, setTranscriptIdsToBookmarkIds])

  const activeTerm = params.get("active-term")
  useEffect(() => {
    if (refresh) {
      // Remove the refresh param from the URL
      const newParams = new URLSearchParams(params)
      newParams.delete("refresh")
      history.replace({ search: newParams.toString() })
    }
    if (selectedTranscriptId && transcriptsData) {
      const selectedTranscript = obtainSelectedTranscript(transcriptsData.transcripts, selectedTranscriptId)
      if (selectedTranscript) {
        // if there is an active-term we need to find the exact word start_time and minus 5 seconds from that
        if (activeTerm) {
          const wordTime = getWordTime(activeTerm, selectedTranscript)
          setVideoStartTime({ startTime: wordTime - 5, change: true })
        } else {
          setVideoStartTime({ startTime: selectedTranscript.start_time - 5, change: true })
        }
      }
    }
  }, [selectedTranscriptId, JSON.stringify(transcriptsData), refresh])

  useEffect(() => {
    const oldterms =
      decodeURIComponent(params.get("searchTerms") || "")
        ?.split(",")
        .filter((term) => term) || []
    if (oldterms > terms) {
      const newParams = new URLSearchParams(params)
      newParams.delete("terms")
      oldterms.forEach((term) => newParams.append("terms", term))
      history.replace({ search: generateParamsString({ terms: oldterms, prevParams: searchString }) })
    }
  }, [searchString])

  const transcripts: [string, TranscriptWithPerson][] = useMemo(() => {
    const termHits: { [index: string]: number } = Object.fromEntries(terms.map((term) => [term, 0]))
    let memoTranscripts: [string, TranscriptWithPerson, number][] = []
    if (data) {
      data.terms.forEach((term_hit) => {
        const transcriptHits = term_hit.transcripts
        if (transcriptHits.length) {
          transcriptHits.forEach((transcript) => {
            const wordTime = getWordTime(term_hit.term, transcript)
            const personRecord = matchPersonToTranscriptFromVoices(voices, transcript)
            memoTranscripts.push([term_hit.term, { ...transcript, person: personRecord }, wordTime])
          })
          termHits[term_hit.term] = term_hit.hits
        }
      })
      memoTranscripts = memoTranscripts.sort((a, b) => a[2] - b[2])
    } else if (voiceIds && transcriptsData && !isMeetingSearchLoading) {
      const filteredTranscripts = transcriptsData.transcripts.filter((transcript) => {
        const voiceId = transcript?.voice_id?.toString()
        if (voiceId) {
          return voiceIds.includes(voiceId)
        }
        return false
      })
      filteredTranscripts.forEach((transcript) => {
        const wordTime = getWordTime("", transcript)
        const personRecord = matchPersonToTranscriptFromVoices(voices, transcript)
        memoTranscripts.push(["", { ...transcript, person: personRecord }, wordTime])
      })
      memoTranscripts = memoTranscripts.sort((a, b) => a[2] - b[2])
    }
    return memoTranscripts.map(([term, transcript]) => [term, transcript])
  }, [
    JSON.stringify(data),
    isMeetingSearchLoading,
    JSON.stringify(terms),
    voiceIds,
    JSON.stringify(voices),
    JSON.stringify(transcriptsData),
  ])

  const { description } = useContext(ClipShareContext)

  useEffect(() => {
    if (selectedTranscriptId && transcriptsData) {
      const selectedTranscript = obtainSelectedTranscript(transcriptsData.transcripts, selectedTranscriptId)
      if (selectedTranscript) {
        const term = params.get("active-term")
        const personRecord = matchPersonToTranscriptFromVoices(voices, selectedTranscript)
        if (term) {
          setSelectedComponent(
            <>
              <TranscriptListItem
                searchTerm={term}
                transcript={{ ...selectedTranscript, person: personRecord }}
                isSelected
                meetingData={meeting}
              />
              {description.trim().length !== 0 && (
                <Box fontWeight={40} border={1} padding={2}>
                  <Typography variant="h5" fontWeight="bold">
                    Notes
                  </Typography>
                  <Typography marginTop={1} fontWeight={500} variant="body2" whiteSpace="pre-wrap" color="black">
                    {description}
                  </Typography>
                </Box>
              )}
            </>,
          )
        }
      }
    }
  }, [selectedTranscriptId, JSON.stringify(transcriptsData), JSON.stringify(voices)])

  return (
    <BookmarkContext.Provider value={{ transcriptIdsToBookmarkIds, setTranscriptIdsToBookmarkIds }}>
      <Stack>
        <TotalMatchesChip
          searchTerms={terms}
          meetingSearchResult={data || ({} as MeetingSearchResult)}
          isMeetingSearchResultLoading={isMeetingSearchLoading}
        />
        <Box>
          <Divider sx={{ marginY: 1 }} />
          {transcriptDataIsLoading ? <TranscriptListItemSkeleton isSelected /> : <>{selectedComponent}</>}
        </Box>
        <Divider sx={{ marginY: 1 }} />
        <TranscriptsListContainer
          searchResults={data ?? ({} as MeetingSearchResult)}
          meetingData={meeting}
          transcripts={transcripts}
          isLoading={isMeetingSearchLoading || isMeetingSearchRefetching}
        />
      </Stack>
      <BookmarkEditor />
      <ShareModal />
    </BookmarkContext.Provider>
  )
}
