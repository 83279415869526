import { AdminContact, Contact } from "./contact"
import { RubyObject } from "./default"
import { Person } from "./person"
import { Transcript } from "./transcript"

export enum VoiceVerificationStatus {
  Unverified = "unverified",
  HumanVerified = "human_verified",
  ContactVerified = "contact_verified",
  RepeatVerified = "repeat_verified",
}

export const VoiceVerificationStatusMap = {
  [VoiceVerificationStatus.Unverified]: "Unverified",
  [VoiceVerificationStatus.HumanVerified]: "Human Verified",
  [VoiceVerificationStatus.ContactVerified]: "Contact Verified",
  [VoiceVerificationStatus.RepeatVerified]: "Repeat Verified",
}

export const VoiceVerificationStatusToInteger: Record<VoiceVerificationStatus, number> = {
  [VoiceVerificationStatus.Unverified]: 0,
  [VoiceVerificationStatus.HumanVerified]: 1,
  [VoiceVerificationStatus.ContactVerified]: 2,
  [VoiceVerificationStatus.RepeatVerified]: 3,
}

export interface Voice<T extends AdminContact | Contact = AdminContact> extends RubyObject {
  person_id: number | null
  verification_status: VoiceVerificationStatus
  person?: Person<T>
}

export interface VoiceWithSpeaker<T extends AdminContact | Contact = AdminContact> extends Voice<T> {
  speaker?: string // EXAMPLE: "Speaker 1"
}

export interface VoiceWithTranscript<T extends AdminContact | Contact = AdminContact> extends Voice<T> {
  transcripts?: Partial<Transcript>[]
}
