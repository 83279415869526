import moment from "moment"
import { useMutation, useQuery, useQueryClient, UseQueryOptions } from "react-query"
import { useAuthAxios } from "./axiosInstance"
import { updateQuickstartSearched } from "./users"
import { RubyObject } from "../interfaces/default"
import { BaseMeeting } from "../interfaces/meeting"
import { Transcript, TranscriptHits } from "@interfaces/transcript"
import { PublicInsight } from "../interfaces/insight"
import { NaicsCode } from "@src/interfaces/naicsCode"
import { Opportunity } from "@src/interfaces/opportunity"
import { InsightCreditUsage } from "@interfaces/insightCreditUsage"
import { generateMinnieURL } from "@functions/generateURL"
import { VoiceWithSpeaker } from "@interfaces/voice"
import { Contact } from "@interfaces/contact"

const prefix = "/meetings"

export const useSearchMeeting = (meetingId: undefined | number, searchTerms: any) => {
  const queryClient = useQueryClient()
  const axiosInstance = useAuthAxios()
  return useQuery(
    ["transcripts", searchTerms, meetingId],
    async () => {
      const response = await (
        await axiosInstance
      ).post<{
        transcripts_by_phrase: TranscriptHits[]
        total_hits: number
        meeting_hits: Record<string, number>
      }>(`/meetings/${meetingId}/transcripts_by_phrase`, {
        searchTerms,
        page_of_origin: "meeting_detail",
      })
      const { data } = response
      data.transcripts_by_phrase = data?.transcripts_by_phrase?.sort((a, b) => (a.hits.length > b.hits.length ? -1 : 1))
      return data
    },
    {
      enabled: typeof meetingId === "number" && searchTerms?.filter((term: string) => term !== "")?.length > 0,
      onSuccess: () => updateQuickstartSearched(queryClient),
    },
  )
}

export interface FetchedMeeting extends BaseMeeting {
  city: string
  county: string
  state: string
  organization_name: string
  has_viewed: boolean
}

export const useFetchMeeting = (meetingId: string, onSuccess?: (meeting: FetchedMeeting) => void) => {
  const axiosInstance = useAuthAxios()

  return useQuery(
    [`meeting_${meetingId}`],
    async () => {
      const response = await (await axiosInstance).get<{ meeting: FetchedMeeting }>(`/meetings/${meetingId}`)

      return response.data
    },
    {
      onSuccess: (response) => {
        if (onSuccess) {
          onSuccess(response.meeting)
        }
      },
    },
  )
}

export const getMeetingVoices = (id: unknown) => {
  const axiosInstance = useAuthAxios()

  return useQuery(
    ["voices", { meeting_id: id }],
    async () => {
      return (await (await axiosInstance).get<VoiceWithSpeaker<Contact>[]>(`${prefix}/${id}/voices`)).data
    },
    {
      enabled: Boolean(id),
    },
  )
}

export const useUpdateMeeting: any = (orgId: string) => {
  const axiosInstance = useAuthAxios()
  const queryClient = useQueryClient()

  return useMutation(
    async (params: any) => {
      const convertedDate = moment(params.publishedAt)

      return (await axiosInstance).put(`/admin/organizations/${params.id}/meetings/${params.meetingId}`, {
        title: params.title,
        source_video_url: params.sourceVideoUrl,
        published_at: convertedDate?.toString(),
        duration_seconds: params.duration,
      })
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(`organization_meetings_${orgId}`)
      },
    },
  )
}

export const useDeleteMeetings: any = (orgId: string) => {
  const axiosInstance = useAuthAxios()
  const queryClient = useQueryClient()

  return useMutation(
    async (params: any) => {
      return (await axiosInstance).delete(
        `/admin/organizations/${params.orgId}/meetings/${params.meetingIds.join(",")}`,
      )
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(`organization_meetings_${orgId}`)
      },
    },
  )
}

export const useNewMeeting: any = (orgId: string) => {
  const axiosInstance = useAuthAxios()
  const queryClient = useQueryClient()

  return useMutation(
    async (params: any) => {
      const convertedDate = moment(params.publishedAt)
      return (await axiosInstance).post(`/admin/organizations/${orgId}/meetings`, {
        title: params.title,
        source_video_url: params.sourceVideoUrl,
        published_at: convertedDate?.toString(),
        duration_seconds: params.duration,
      })
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(`organization_meetings_${orgId}`)
      },
    },
  )
}

export const useRunTranscriptionJobs: any = (orgId: string) => {
  const axiosInstance = useAuthAxios()
  const queryClient = useQueryClient()

  return useMutation(
    async (params: any) => {
      return (await axiosInstance).post(
        `/admin/organizations/${orgId}/meetings/${params?.meetingIds?.join(",")}/run_transcription_jobs`,
        params,
      )
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(`organization_meetings_${orgId}`)
      },
    },
  )
}

export const useDeleteTranscriptsForMeetings: any = (orgId: string) => {
  const axiosInstance = useAuthAxios()
  const queryClient = useQueryClient()

  return useMutation(
    async (params: any) => {
      return (await axiosInstance).post(
        `/admin/organizations/${orgId}/meetings/${params?.meetingIds?.join(",")}/delete_transcripts`,
        params,
      )
    },
    {
      onSuccess: () => queryClient.invalidateQueries(`organization_meetings_${orgId}`),
    },
  )
}

export const useCalculateMetadata: any = (orgId: string) => {
  const axiosInstance = useAuthAxios()
  const queryClient = useQueryClient()

  return useMutation(
    async (params: any) => {
      return (await axiosInstance).post(
        `/admin/organizations/${orgId}/meetings/${params?.meetingIds?.join(",")}/calculate_metadata`,
      )
    },
    {
      onSuccess: () => queryClient.invalidateQueries(`organization_meetings_${orgId}`),
    },
  )
}

export const useFetchMeetingTranscripts = (meetingId?: string) => {
  const axiosInstance = useAuthAxios()

  return useQuery(
    ["meetings", meetingId, "transcripts"],
    async () => {
      const response = await (
        await axiosInstance
      ).get<{ transcripts: Transcript[] }>(`/meetings/${meetingId}/transcripts`)

      return response.data
    },
    {
      enabled: meetingId !== undefined,
    },
  )
}

export const useFetchAdminMeetings: any = (page: number) => {
  const axiosInstance = useAuthAxios()

  return useQuery(["admin_meetings", page], async () => {
    const response = await (await axiosInstance).get(`/admin/meetings?page=${page}`)

    return response.data
  })
}

export const useFetchSpamMeetings: any = (page: number, daysBack: number) => {
  const axiosInstance = useAuthAxios()

  return useQuery(["spam_meetings", page, daysBack], async () => {
    const response = await (
      await axiosInstance
    ).get(`/admin/meetings/spam_meetings?page=${page + 1}&days_back=${daysBack}`)

    return response.data
  })
}

export const useUpdateSpam: any = (organizationId?: number | undefined) => {
  const axiosInstance = useAuthAxios()
  const queryClient = useQueryClient()

  return useMutation(
    async (params: any) => {
      return (await axiosInstance).put(`/admin/meetings/${params.ids}`, params)
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("admin_meetings")
        queryClient.invalidateQueries("spam_meetings")
        queryClient.invalidateQueries(["meetings", { organization_id: organizationId?.toString() }])
        queryClient.invalidateQueries(`organization_meetings_${organizationId}`)
      },
    },
  )
}

export interface MeetingSpamCheck {
  meeting_id: number
  is_spam: boolean
  confidence_score: number
  reasoning: string
}

export const useCheckSpam = (meeting_ids: number[], custom_prompt?: string) => {
  const axiosInstance = useAuthAxios()
  if (custom_prompt?.trim() === "") custom_prompt = undefined
  return useQuery(
    ["check_spam", meeting_ids],
    async () => {
      const response = await (
        await axiosInstance
      ).get<MeetingSpamCheck[]>(`/admin/meetings/check_spam`, {
        params: { meeting_ids, custom_prompt },
      })

      return response.data
    },
    {
      refetchOnWindowFocus: false,
      enabled: false,
    },
  )
}

export const useFetchSimilarMeetings = (meetingId?: number) => {
  const axiosInstance = useAuthAxios()

  return useQuery(
    ["similar_meetings", meetingId],
    async () => {
      const response = await (await axiosInstance).get(`/meetings/${meetingId}/similar_meetings`)

      return response.data
    },
    {
      enabled: meetingId !== undefined,
    },
  )
}

export const useFetchScrapingEngine: any = (daysBack: number) => {
  const axiosInstance = useAuthAxios()

  return useQuery(["scraping_engine", daysBack], async () => {
    const response = await (await axiosInstance).get(`/admin/meetings/scraping_engine?days_back=${daysBack}`)

    return response.data
  })
}

export const useCreateSimilarMeetingLog: any = () => {
  const axiosInstance = useAuthAxios()

  return useMutation(async (params: { from_meeting_id: number; to_meeting_id: number }) => {
    return (await axiosInstance).post<RubyObject>("/meetings/create_similar_meeting_log", params)
  })
}

export const useMarkViewed = (meetingId: string) => {
  const axiosInstance = useAuthAxios()
  const queryClient = useQueryClient()

  return useMutation(
    async () => {
      const response = await (await axiosInstance).post(`/meetings/${meetingId}/mark_viewed`)

      return response.data
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["search"])
        queryClient.invalidateQueries([`meeting_${meetingId}`])
      },
    },
  )
}

export const getMeetingInsight = (id: number | string, onSuccess?: (data: string) => void) => {
  const axiosInstance = useAuthAxios()

  return useQuery(
    ["meetings", id, "insight"],
    async () => {
      return (await (await axiosInstance).get<string>(`${prefix}/${id}/briefing`)).data
    },
    {
      onSuccess,
      enabled: Boolean(id),
    },
  )
}

export const getMeetingPersonalInsight = (id: number | string, onSuccess?: (data: PublicInsight[]) => void) => {
  const axiosInstance = useAuthAxios()

  return useQuery(
    ["meetings", id, "personalInsight"],
    async () => {
      return (await (await axiosInstance).get<PublicInsight[]>(`meetings/${id}/personal_briefing`)).data
    },
    {
      onSuccess,
      enabled: Boolean(id),
    },
  )
}

// this will deprecate getMeetingPersonalInsight and getMeetingInsight.
// TO DO: remove those after confirming they're gone and there are no regressions
export const useFetchMeetingInsights = (
  id: unknown,
  options?: Omit<UseQueryOptions<string, unknown, PublicInsight[], unknown[]>, "queryKey" | "queryFn">,
) => {
  const axiosInstance = useAuthAxios()

  return useQuery(
    ["meetings", id, "insights"],
    async () => {
      return (await (await axiosInstance).get(`${prefix}/${id}/briefings`)).data
    },
    {
      enabled: Boolean(id),
      ...options,
    },
  )
}

export const useFetchMeetingInsightCreditUsages = (
  id: unknown,
  options?: Omit<UseQueryOptions<string, unknown, InsightCreditUsage[], unknown[]>, "queryKey" | "queryFn">,
) => {
  const axiosInstance = useAuthAxios()

  return useQuery(
    ["meetings", id, "insight_credit_usages"],
    async () => {
      return (await (await axiosInstance).get(`${prefix}/${id}/briefing_credit_usages`)).data
    },
    {
      enabled: Boolean(id),
      ...options,
    },
  )
}

export const useFetchMeetingInsightCreditUsagesMap = (
  id: unknown,
  options?: Omit<UseQueryOptions<string, unknown, InsightCreditUsage[], unknown[]>, "queryKey" | "queryFn">,
) => {
  const fetchData = useFetchMeetingInsightCreditUsages(id, options)
  if (fetchData.data) {
    const insightCreditUsagesMap = new Map<number, InsightCreditUsage[]>()
    fetchData.data.forEach((insightCreditUsage) => {
      const insightCreditUsagesList = insightCreditUsagesMap.get(insightCreditUsage.briefing_id)
      if (!insightCreditUsagesList) {
        insightCreditUsagesMap.set(insightCreditUsage.briefing_id, [insightCreditUsage])
      } else {
        insightCreditUsagesMap.set(insightCreditUsage.briefing_id, [...insightCreditUsagesList, insightCreditUsage])
      }
    })
    return {
      ...fetchData,
      data: insightCreditUsagesMap,
    }
  }
  return fetchData
}

interface MeetingInsightCostResponse {
  cost: number
}

export const getMeetingsInsightCost = (meeting_id: number, number_of_prompts: number = 1) => {
  const axiosInstance = useAuthAxios()
  const url_path = generateMinnieURL("minnie/briefings/cost")
  return useQuery(
    ["insight_cost", { meeting: meeting_id }],
    async () => {
      const request_url = url_path + `?meeting_id=${meeting_id}&number_of_prompts=${number_of_prompts}`
      return (await (await axiosInstance).get<MeetingInsightCostResponse>(request_url)).data
    },
    {
      enabled: Boolean(meeting_id),
    },
  )
}

export const useCreateGeneralInsight = (id: unknown, onSuccess?: () => void) => {
  const axiosInstance = useAuthAxios()

  return useMutation(
    async () => {
      return (await axiosInstance).post(`/meetings/${id}/general_briefing`)
    },
    {
      onSuccess,
    },
  )
}

export const useCreatePersonalInsight = (id: unknown, onSuccess?: () => void) => {
  const axiosInstance = useAuthAxios()
  const queryClient = useQueryClient()

  return useMutation(
    async (params: { promptIds: number[] }) => {
      return (await axiosInstance).post(`/meetings/${id}/personal_briefing`, { prompt_ids: params.promptIds })
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["meetings", id, "insights"])
        queryClient.invalidateQueries(["meetings", id, "insight_credit_usages"])
        onSuccess?.()
      },
    },
  )
}

interface MeetingOpportunity extends Opportunity {
  naics_code: NaicsCode
}
export const useFetchMeetingOpportunities = (meetingId?: unknown) => {
  const axiosInstance = useAuthAxios()

  return useQuery(
    ["meetings", `${meetingId}`, "opportunities"],
    async () => {
      const response = await (await axiosInstance).get<MeetingOpportunity[]>(`${prefix}/${meetingId}/opportunities`)

      return response.data
    },
    {
      enabled: meetingId !== undefined,
    },
  )
}
